@import './themes';

@mixin nb-overrides() {
    nb-select.size-medium button {
        padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

        nb-icon {
            right: 0.41rem !important;
        }
    }

    nb-card nb-list {
        @include nb-scrollbars(
            nb-theme(card-scrollbar-color),
            nb-theme(card-scrollbar-background-color),
            nb-theme(card-scrollbar-width)
        );
    }

    nb-card.accent {
        border-top-width: 0.25rem;
    }

    .table {
        color: nb-theme(text-basic-color) !important;
    }

    hr {
        width: 100%;
    }
    .no-margin {
        margin: 0;
    }
    .required::after {
        content: '*';
    }

    table {
        width: 100%;

        tr {
            border-bottom: nb-theme(card-border-width) double nb-theme(border-primary-color-1);
            &.mat-row {
                border-color: nb-theme(card-border-color);
            }
            &.mat-row:last-child {
                border-bottom: hidden;
            }
        }

        .mat-header-row {
            position: sticky;
            top: 0;
            z-index: 10;
            background: nb-theme(card-background-color);
        }

        th.mat-header-cell:not(:last-of-type),
        td.mat-cell:not(:last-of-type) {
            padding-right: 1rem;
        }
    }

    nb-form-field,
    .nb-form-field {
        margin: 0;
    }
}
